import React, { useState, useEffect } from 'react';
import './Filter.scss'
import { Event } from '../../domain/game/Event';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Props = {
    events: Event[],
    filterChanged: (steamId: string, eventId: number) => void
}

export const Filter: React.FC<Props> = ({ events, filterChanged }) => {
    const [eventFilter, setEventFilter] = useState(0);
    const [steamFilter, setSteamFilter] = useState("");

    useEffect(() => {
        filterChanged(steamFilter, +eventFilter)
    }, [steamFilter, eventFilter, filterChanged]);

    return (
        <div className="filter-wrapper row">
            <div className="input-group col-6">
                <div className="input-group-prepend">
                    <label className="input-group-text" id="steamIdDescriptor"><FontAwesomeIcon icon="user" /></label>
                </div>
                <input maxLength={17} minLength={17} aria-label="Username" aria-describedby="steamIdDescriptor" className="form-control" onChange={e => { if (e.target.value.length === 17) setSteamFilter(e.target.value); }} type="text" placeholder="SteamID (64Bit)" />
            </div>
            <div className="input-group col-6">
                <div className="input-group-prepend">
                    <label className="input-group-text" aria-label="Event" aria-describedby="steamIdDescriptor" >Event</label>
                </div>
                <select className="custom-select" id="eventSelect" onChange={(e) => setEventFilter(+e.target.value)} >
                    <option key={0} value={0} selected>All Events</option>
                    {

                        events.map((event) => {
                            return (<option key={event.eventId} value={event.eventId}> {event.title} </option>)
                        })
                    }
                </select>
            </div>
        </div>
    );
}