import React from 'react';
import { Team } from '../../domain/game/Team';
import { PlayerRow } from './PlayerRow/PlayerRow'
import './Scoreboard.scss'

type Props = {
    team: Team
};

export const Scoreboard: React.FC<Props> = ({ team}) => {
    return (
            <table className={`scoreboard content-box col-12 table`}>
                <thead className="thead-dark">
                    <tr>
                        <th className="name-header">{team.name}</th>
                        <th>Kills</th>
                        <th>Deaths</th>
                        <th>Assists</th>
                        <th>MVP</th>
                        <th>Score</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        team.players
                            // .sort((a, b) => {
                            //     return +a.stats.filter(s => s.stat.key === "Score") + +b.stats.filter(s => s.stat.key === "Score");
                            // })
                            .map(p => {
                                return (
                                    <PlayerRow player={p} key={p.teamPlayerId} />
                                )
                            })
                    }
                </tbody>
            </table>
    );
}