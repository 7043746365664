import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { GameService } from '../../services/GameService/GameService';
import { Game } from '../../domain/game/Game'
import { Scoreboard } from '../../components/Scoreboard/Scoreboard'
import './game-details.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactGA from "react-ga";

const gameService = new GameService();

type Props = {
    id: string;
}

export const GameDetails: React.FC<RouteComponentProps<Props>> = ({ match }: RouteComponentProps<Props>) => {

    const [game, setGame] = useState<Game>();

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);


    useEffect(() => {
        const fetchData = async () => {
            const result = await gameService.getById(+match.params.id)
            setGame(result)
        };
        fetchData();
    }, [match.params.id]);

    if (!game) {
        return (<div></div>)
    }

    return (
        <div className="game-details-wrapper container">
            <div className="details-header content-box row">
                <div className="col-2">
                    <div className="map">
                        <img className="d-none d-md-inline" src={`/images/maps/${game.map.name}.jpg`} alt={game.map.name} />
                        <span className="map-name-bottom d-none d-md-inline">{game.map.name}</span>
                        <span className="map-name-middle d-block d-md-none">{game.map.name}</span>
                    </div>
                </div>
                <div className="col-8 title">
                    <div className="row">
                        <div className="col-5 counter-terrorist">
                            <div>{game.teams[0].name} </div>
                        </div>
                        <div className="col-2">vs</div>
                        <div className="col-5 terrorist">
                            <div>{game.teams[1].name} </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-5 counter-terrorist">
                            {game.teams[0].score}
                        </div>
                        <div className="col-2">{game.event ? game.event.title : ""}</div>
                        <div className="col-5 terrorist">
                            <div>{game.teams[1].score} </div>
                        </div>
                    </div>
                </div>
                <div className="col-1">
                    <a href={`/api/games/${game.gameId}/download`}>
                        <FontAwesomeIcon className="download-button" icon="download" />
                    </a>
                </div>
            </div>
            <div className="scoreboards-wrapper">
                <div className="row">
                    <Scoreboard team={game.teams[0]} />
                </div>
                <div className="row">
                    <Scoreboard team={game.teams[1]} />
                </div>
            </div>
        </div>
    )
}