import React, { useState, useEffect } from 'react';
import { GamesSummaryTable } from '../GamesSummaryTable/GamesSummaryTable'
import { Game } from '../../domain/game/Game';
import { GameService } from '../../services/GameService/GameService';

type Props = {
    // getGameSummaries: (index: number, count: number) => Promise<GameSummaryDto>;
    gameService: GameService
}

export const GamesSummary: React.FC<Props> = ({ gameService }) => {
    const [index, setIndex] = useState(1);
    const [count, setCount] = useState(5);
    const [gameSummary, setGameSummary] = useState({ total: 0, games: new Array<Game>() })
    const [total, setTotal] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            const result = await gameService.getGameSummaries(index, count);
            setTotal(result.total);
            setGameSummary(result);
        };
        fetchData();
    }, [index, count, gameService]);

    function setPage(pageNo: number): void {
        setIndex(((pageNo - 1) * count) + 1 );
    }

    return (
        <div>
            <div>
                <GamesSummaryTable
                    summary={gameSummary}
                    setPage={setPage}
                    setCount={(count: number) => setCount(count)}
                    selectedPage={ Math.ceil(index / count) }
                    total={total} 
                    count={count} />
            </div>
        </div>
    );
}