import React, { useContext } from 'react';
import { GameSummaryDto } from '../../domain/game/GameSummaryDto';
import { __RouterContext } from 'react-router';
import './GamesSummaryTable.scss'
import { PaginationBar } from '../PaginationBar/PaginationBar'

type Props = {
    summary: GameSummaryDto,
    selectedPage: number,
    total: number,
    count: number,
    setPage: (pageNo: number) => void,
    setCount: (count: number) => void
}


export const GamesSummaryTable: React.FC<Props> = ({ summary, setPage, setCount, selectedPage, total, count }) => {
    const routerContext = useContext(__RouterContext);

    const handleClick = (gameId: number) => {
        routerContext.history.push(`/games/${gameId}`);
    }

    return (
        <div>
            <table className="summary-table table col-12">
                <thead className="thead-dark">                    
                    <th className="map-col">Map</th>
                    <th className="event-col">Event</th>
                    <th className="teams-col">Teams</th>
                    <th className="score-col">Score</th>
                </thead>
                <tbody>
                    {summary.games.map(s => {
                        return (
                            <tr onClick={() => handleClick(s.gameId)}>
                                <td>
                                    <img src={`/images/maps/${s.map.name}.jpg`} alt={s.map.name}/>
                                </td>
                                <td>{s.event ? s.event.title : ""}</td>
                                <td className="teams-col">
                                    <div className="counter-terrorist">{s.teams[0].name}</div> vs <div className="terrorist">{s.teams[1].name}</div>
                                </td>
                                <td>
                                    <span className="counter-terrorist">{s.teams[0].score}</span> - <span className="terrorist">{s.teams[1].score}</span>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>

            <PaginationBar setPage={setPage} setCount={setCount} selectedPage={selectedPage} total={total} count={count} />
        </div>
    );
}
