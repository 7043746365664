import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './PaginationBar.scss'

type Props = {
    selectedPage: number,
    total: number,
    count: number,
    setPage: (pageNo: number) => void,
    setCount: (count: number) => void
}

export const PaginationBar: React.FC<Props> = ({ setPage, setCount, selectedPage, total, count }) => {
    const totalPages = Math.ceil(total / count);

    let navivationPages: number[] = (() => {
        var x = new Array<number>();        
        for (let index = (selectedPage - 4); (index < (selectedPage + 4) && index <= totalPages); index++) {
            if (index > 0 && index <= totalPages) x.push(index);
        }

        return x;
    })();


    return (
        <div className="pagination-bar">
            {
                ((selectedPage - 10) > 0) &&
                <span className="pagination-button" onClick={() => setPage(1)}>
                    <FontAwesomeIcon icon="step-backward" />
                </span>
            }
            {
                ((selectedPage - 4) > 0) &&
                <span className="pagination-button" onClick={() => setPage(selectedPage - 4)}>
                    <FontAwesomeIcon icon="backward" />
                </span>
            }
            {
                navivationPages.map(pn => {
                    return (
                        <span className={`pagination-button number ${pn === selectedPage ? "selected" : ""}`} onClick={() => setPage(pn)}>
                            {pn}
                        </span>
                    );
                })
            }
            {
                ((selectedPage + 4) <= totalPages) &&
                <span className="pagination-button" onClick={() => setPage(selectedPage + 4)}>
                    <FontAwesomeIcon icon="forward" />
                </span>
            }
            {
                ((selectedPage + 10) <= totalPages) &&
                <span className="pagination-button" onClick={() => setPage(totalPages)}>
                    <FontAwesomeIcon icon="fast-forward" />
                </span>
            }

        </div>
    );
}