import axios from 'axios';
import { GameSummaryDto } from '../../domain/game/GameSummaryDto';
import { Game } from '../../domain/game/Game';

export class GameService {
    public _steamId: string = "";
    public _eventId: number = 0;

    public getGameSummaries(index: number, count: number): Promise<GameSummaryDto> {
        return axios.get('api/games/summary', { params: { index: index, count: count - 1, steamId: this._steamId, eventId: this._eventId } })        
            .then((response) => {
                let data: GameSummaryDto = response.data;
                data.games.forEach(g => { g.teams[0].name = g.teams[0].name || "Counter-Terrorists"; g.teams[1].name = g.teams[1].name || "Terrorists"; });
                return data;
            }
            );
    }

    public getFilteredGameSummaries(index: number, count: number): Promise<GameSummaryDto> {
        return axios.get('/games/summary', { params: { index: index, count: count, steamId: this._steamId, eventId: this._eventId } })
            .then((response) => {
                let data: GameSummaryDto = response.data;
                data.games.forEach(g => { g.teams[0].name = g.teams[0].name || "Counter-Terrorists"; g.teams[1].name = g.teams[1].name || "Terrorists"; });
                return data;
            }
            );
    }

    public getById(id: number): Promise<Game> {
        return axios.get(`/api/games/${id}/details`)
            .then((response) => {
                let game: Game = response.data;
                game.teams[0].name = game.teams[0].name || "Counter-Terrorists";
                game.teams[1].name = game.teams[1].name || "Terrorists";
                return game;
            });
    }
}