import React, {useEffect} from 'react';
import './App.css';
import { Homepage } from './pages/homepage'
import { GameDetails } from './pages/game-details/game-details'
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Header } from './components/Header/Header'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faForward, faFastForward, faBackward, faStepBackward, faDownload, faUser } from '@fortawesome/free-solid-svg-icons'
import ReactGA from 'react-ga';
import {GoogleAnalytics} from "./components/GoogleAnalytics/GoogleAnalytics";

const TRACKING_ID = "UA-226895912-3"; // OUR_TRACKING_ID


const App: React.FC = () => {
  library.add(faForward);
  library.add(faFastForward);
  library.add(faBackward);
  library.add(faStepBackward);
  library.add(faDownload);
  library.add(faUser);
  ReactGA.initialize(TRACKING_ID);

  return (
    <div className="App">
      <link href="https://fonts.googleapis.com/css?family=Rajdhani:300,500,700&subset=" rel="stylesheet" />
      <Router>
        <Header />
        <div className="container-fluid content">
          <Route path="/" exact component={Homepage} />
          <Route exact path="/games/:id" component={GameDetails} />
        </div>
        <GoogleAnalytics />
      </Router>
    </div>
  );
}

export default App;
