import React from 'react';
import { TeamMemberWrapper } from '../../../domain/game/TeamMemberWrapper';
import { PlayerStatRow } from './PlayerStatRow/PlayerStatRow'

type Props = {
    player: TeamMemberWrapper;
}

export const PlayerRow: React.FC<Props> = ({ player }) => {

    var name = player.nickname;
    var kills = player.stats.filter(s => s.stat.key === "Kills")[0];
    var deaths = player.stats.filter(s => s.stat.key === "Deaths")[0];
    var assists = player.stats.filter(s => s.stat.key === "Assists")[0];
    var mvps = player.stats.filter(s => s.stat.key === "MVPs")[0];
    var score = player.stats.filter(s => s.stat.key === "Score")[0];

    return (
        <tr className="player-row">
            <td>{name}</td>
            <PlayerStatRow stat={kills} />
            <PlayerStatRow stat={deaths} />
            <PlayerStatRow stat={assists} />
            <PlayerStatRow stat={mvps} />
            <PlayerStatRow stat={score} />
        </tr>
    );
}