import React, {useEffect} from "react";
import ReactGA from "react-ga";
import { useLocation } from "react-router"

export const GoogleAnalytics: React.FC = () => {
    let loc = useLocation();

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
        console.log("Called!");
    }, [loc]);

    return null;
}