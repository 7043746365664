import React, { useContext } from 'react';
import './Header.scss';
import { __RouterContext } from 'react-router';


export const Header: React.FC = () => {
    const routerContext = useContext(__RouterContext);

    const handleClick = () => {
        routerContext.history.push(`/`);
    }

    return (
        <div id="header" className="row">
            <div className="col-12">
                <button id="site-logo" className="btn btn-link" type="button" onClick={() => handleClick()}>
                    <img  alt="UKCSGO" src="/images/banner_vault.png" />
                </button>
            </div>
        </div>
    )
}
