import React from 'react';
import { PlayerStat } from '../../../../domain/player/PlayerStat';

type Props = {
    stat: PlayerStat;
}

export const PlayerStatRow: React.FC<Props> = ({ stat }) => {
    return (
        <td className={`stat-value stat-${stat.stat.key.toLowerCase()}` }>{stat.value || '-'}</td>
    );
}
