import React, { useState, useEffect } from 'react';
import { GamesSummary } from '../components/GamesSummary/GamesSummary';
import { EventsService } from '../services/EventsService/EventsService';
import { GameService } from '../services/GameService/GameService';
import { Filter } from '../components/Filter/Filter';
import { Event } from '../domain/game/Event';
import './homepage.scss';

const eventsService = new EventsService();

export const Homepage: React.FC = () => {
    const [events, setEvents] = useState(new Array<Event>());
    const [steamId, setSteamId] = useState<string>("");
    const [eventId, setEventId] = useState<number>(0);
    const [gameService, setGameService] = useState(new GameService());

    const filterAction = (steamId: string, event: number) => {
        setSteamId(steamId);
        setEventId(event);
    }

    useEffect(() => {
        const fetchData = async () => {
            const result = await eventsService.getEvents();
            setEvents(result);
        };

        fetchData();
    }, []);

    useEffect(() => {
        var y = new GameService();
        y._eventId = eventId;
        y._steamId = steamId;
        setGameService(y);
    }, [steamId, eventId]);


    return (
        <div className="container">
            <div><Filter events={events} filterChanged={filterAction} /></div>
            <div><GamesSummary gameService={gameService} /></div>
        </div>
    );
}


